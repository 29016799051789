h2 {
  text-align: center;
  padding: 20px 0;
}

.table-bordered {
  border: 1px solid #383E49 !important;
}

table caption {
  padding: .5em 0;
}

@media screen and (max-width: 767px) {
  table caption {
    display: none;
  }
}

.p {
  text-align: center;
  padding-top: 140px;
  font-size: 14px;
}

.agentSearchClass:active,
.agentSearchClass:focus,
.agentSearchClass:hover {
  box-shadow: 0 0 0 2px transparent,
    0 0 0 3px transparent;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 2;
}
.searchOutContainer{
 text-align: center;
 vertical-align: middle;
 display: flex;
 align-items: center;
 /* justify-content: center; */
}